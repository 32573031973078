<script setup lang="ts">
import City from '~~/utils/models/City';
// import Gallery, { GalleryAttrs } from '~~/utils/models/Gallery';
import Product, { ProductAttrs } from '~~/utils/models/Product';
import Setting from '~/utils/models/Setting';
import Article, { ArticleAttrs } from '~~/utils/models/Article';
import IconArrowRight2 from '~~/assets/svg/icons/arrow-right-2.svg';

useHead({
  title: "SHVR - Unveiling Indonesia's EDM Scene",
  meta: [
    {
      name: 'description',
      content:
        "SHVR: House for #InfiniteCoolness and Party Seekers. Explore Indonesia's EDM Events & Lifestyle Inside!",
    },
  ],
});

const { data: banner } = await useAsyncData(() =>
  Setting.fetchByID('banners.unbranded')
);
const { data: cities } = await useAsyncData(() =>
  City.fetch({ _order: [['name', 'asc']] })
);
// const galleries = ref<GalleryAttrs[]>();
const products = ref<ProductAttrs[]>();
const articles = ref<ArticleAttrs[]>();

onBeforeMount(() => {
  // Gallery.fetch({ type: 'SGF' }).then(({ data }) => (galleries.value = data));
  Product.fetch({ _limit: 3 }).then(({ data }) => (products.value = data));
  Article.fetch({
    _order: [
      ['priority', 'desc'],
      ['publishedAt', 'desc'],
    ],
    _limit: 4,
  }).then(({ data }) => (articles.value = data));
});
</script>

<template>
  <main>
    <template v-if="banner?.value?.items.length">
      <LazyBannerWide :banner="banner" />
      <LazyLayoutSectionDivider />
    </template>

    <LazyCityCarousel
      class="mt-48 lg:mt-96"
      v-if="cities?.data.length"
      :cities="cities.data"
    >
      <template #before>
        <div class="container text-center lg:mb-57">
          <h2 class="text-32 lg:text-64 leading-48 lg:line-height-73">
            SHVR <span class="text-primary">PLACE</span>
          </h2>
          <p class="lg:text-20 lg:leading-28 font-medium mb-32">
            List of events to fulfill your party cravings!
          </p>

          <RouterLink
            class="hidden lg:inline-flex btn btn-primary btn-240x48 gap-12"
            to="/event"
          >
            Explore More
            <IconArrowRight2 />
          </RouterLink>
        </div>
      </template>
      <template #after>
        <div class="container text-center lg:hidden mt-32">
          <RouterLink class="btn btn-primary btn-240x48 gap-12" to="/event">
            Explore More
            <IconArrowRight2 />
          </RouterLink>
        </div>
      </template>
    </LazyCityCarousel>

    <!-- <LazyGallerySGFList v-if="galleries?.length" :galleries="galleries">
    <template #before>
      <h2>Gallery</h2>
      <p>Relive the good times with us once again!</p>
    </template>
    <template #after>
      <RouterLink class="btn btn-primary" to="/gallery"> View All </RouterLink>
    </template>
  </LazyGallerySGFList> -->

    <UnbrandedSHVRGroundFestival class="mt-48 lg:-mt-[6.08%]" />

    <UnbrandedSHVRCoin class="mt-48 lg:mt-[193px]" />

    <!-- <LazyProductCarousel
      class="mt-48 lg:mt-96"
      v-if="products?.length"
      :products="products">
      <template #before>
        <div class="container text-center lg:mb-48">
          <h2 class="text-32 lg:text-64 leading-40 lg:leading-73">
            SHVR <span class="text-primary">MERCH</span>
          </h2>
          <p class="lg:text-20 lg:leading-28 font-medium mb-32">
            Get your own SHVR Merch, by redeeming your SHVR Coin here!
          </p>

          <RouterLink
            class="btn btn-primary btn-240x48 hidden lg:inline-flex gap-12"
            to="/shvrcoin"
          >
            See All Merch
          </RouterLink>
        </div>
      </template>
      <template #after>
        <div class="container text-center lg:hidden mt-32">
          <RouterLink class="btn btn-primary btn-240x48 gap-12" to="/shvrcoin">
            See All Merch
          </RouterLink>
        </div>
      </template>
    </LazyProductCarousel> -->

    <LazyArticleGrid
      class="mt-48 lg:mt-96"
      v-if="articles?.length"
      :articles="articles"
    >
      <template #before>
        <div class="container text-center lg:mb-48">
          <h2 class="text-32 lg:text-64 leading-40 lg:leading-73">
            SHVR <span class="text-primary">ARTICLES</span>
          </h2>
          <p class="lg:text-20 lg:leading-28 font-medium mb-32">
            Know what’s hip to make sure you don’t get left behind!
          </p>

          <RouterLink
            class="btn btn-primary btn-240x48 hidden lg:inline-flex gap-12"
            to="/articles"
          >
            More Articles
          </RouterLink>
        </div>
      </template>
      <template #after>
        <div class="container text-center lg:hidden mt-32">
          <RouterLink
            class="btn btn-primary btn-240x48 hidden lg:inline-flex gap-12"
            to="/articles"
          >
            More Articles
          </RouterLink>
        </div>
      </template>
    </LazyArticleGrid>
  </main>
</template>
